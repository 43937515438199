<template>
  <div class="ForRecord">
    <div v-loading="loading"></div>
    <div v-if="!Isdata" class="RecordList">
      <div v-for="(item, index) in Records.data" :key="index" class="RecordList-item" :class="'RecordList-item'+ (index + 1)">
        <div v-if="clientWidth > 750" class="RecordList-item-date">{{ item.cTime }}</div>
        <div class="RecordList-item-detail flex3">
          <div class="img-d flex2">
            <div class="img-url flex7">
              <img :src="item.imgUrl">
            </div>
            <div class="flex7 RecordList-item-detail-d">
              <p class="RecordList-item-detail-title">{{ item.productName }}</p>
              <p v-if="clientWidth > 750" class="RecordList-item-detail-price"><b class="violet">{{ item.price }}</b> 金币</p>
              <p v-if="clientWidth < 750" class="RecordList-item-detail-date co-999">{{ item.cTime }}</p>
            </div>
          </div>
          <div class="RecordList-item-state ml">
            <div v-if="clientWidth < 750"><p class="RecordList-item-detail-price-mb"><b class="violet">{{ item.price }}</b> 金币</p></div>
            <div v-if="item.state == 0" class="RecordList-item-state-mb tr">审核中</div>
            <div v-if="item.state == 1" class="RecordList-item-state-mb tr">已拒绝</div>
            <div v-if="item.state == 2" class="RecordList-item-state-mb tr">兑换中</div>
            <div v-if="item.state == 3" class="RecordList-item-state-mb tr violet">已发出</div>
            <div v-if="item.state == 8" class="RecordList-item-state-mb tr co-999">已取消</div>
            <div v-if="item.state == 9" class="RecordList-item-state-mb tr co-999">已完成</div>
          </div>
          <div class="RecordList-item-track-mbn" style="min-width: 96px">
            <div v-if="clientWidth > 750 && item.type == 1" class="RecordList-item-track cur" @click="track(item.id)">查看快递单号</div>
          </div>
        </div>
        <div class="RecordList-item-track-mba">
          <div v-if="clientWidth < 750 && item.type == 1" class="RecordList-item-track-mb tr" @click="track(item.id)">查看快递单号</div>
        </div>
      </div>
    </div>
    <div v-else class="noData">
      <img src="@/assets/PC/profile/listNone.png" alt="" />
    </div>
    <div class="page tr">
      <el-pagination
        @current-change="handleCurrentChange"
        :total="Records.recordCount"
        :page-size="Records.pageSize"
        :current-page="Records.pageIndex"
        background
        layout="pager, next"
        next-text="下一页"
        hide-on-single-page>
      </el-pagination>
    </div>
    <div v-if="IsloadList" class="loadList flex0">
      <div class="loadList0" @click="loadlist">加载更多</div>
    </div>
    <div v-if="clientWidth < 750 && !IsloadList && Records.data.length > 0" class="tc notDetail">
      <div>
        没有更多内容了~ 
        <span class="violet" @click="scrollTop">返回顶部</span>
      </div>
    </div>
  </div>
</template>

<script>
import * as profile from "@/api/profile.js";
import { dataState } from "@/api/all.js";
import { GetWxShares } from "@/utils/common";

export default {
  name: 'ForRecord',
  data() {
    return {
      loading: true,                          // !loading
      pages:{page: 1,limit: 5,},              // !分页参数
      Records: {data: []},                    // !兑换记录数组
      IsloadList: false,                      // !移动端加载更多
      Isdata: false,                          // !暂无数据
    }
  },
  mounted(){
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    this.getInit();
    // 数据统计
    const data ={
      "visitType": 1,
      "gid": sessionStorage.getItem('gid') ? sessionStorage.getItem('gid') : '',
      "url": window.location.href,
      "pageUrl": "ForRecord",
      "relationId": ""
    }
    dataState(data).then(res => {
      sessionStorage.setItem('gid',res.data);
    })
  },
  methods: {
    // !初始化列表
    getInit(Ismobile){
      profile.GetOrderHistory(this.pages).then(res => {
        if(res.status == 1){
          if(res.data.length > 0){
            this.Isdata = false;
            if(res.data.length < res.recordCount){
              this.IsloadList = true;
            }
            if(Ismobile && this.clientWidth < 750){
              this.Records.data = this.Records.data.concat(res.data);
              if(this.Records.data.length == res.recordCount) {
                this.IsloadList = false;
              }
            } else {
              this.Records = res;
            }
          }else {
            this.Records = {data: []};
            this.IsloadList = false;
            this.Isdata = true;
          }
          
        }else {
          this.$toast(res.message);
        }
        this.loading = false;
      })
    },
    // !PC端分页
    handleCurrentChange(currentPage){
      this.loading =true;
      this.pages.page = currentPage;
      this.getInit();
    },
    // !点击加载更多
    loadlist() {
      if(this.clientWidth < 750){
        this.loading = true;
        this.pages.page++;
        this.getInit(true);
      }
    },
    // !返回顶部
    scrollTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // !查看快递单号
    track(id) {
      profile.GetExpressNumber({orderId: id}).then(res => {
        if(res.status == 1) {
          if(res.data) {
            this.$alert(res.data, "快递单号", {
              callback: (action) => {},
            });
          }else{
            this.$toast('您兑换的商品还未发出，请持续关注！')
          }
        }else {
          this.$toast(res.message);
        }
      })
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth
    }
  }
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }

  .ForRecord {
    margin: 40px;
    .RecordList {border: 1px solid #DBC3E7;margin-bottom: 20px;}

    .RecordList-item-date {font-size: 12px; height: 40px; border-bottom: 1px solid #DBC3E7; line-height: 40px; padding-left: 12px;}
    .RecordList-item-detail {border-bottom: 1px solid #DBC3E7; padding: 26px 33px; background: #F5EFF8;}
    .img-url {width: 78px; height: 78px; overflow: hidden;background: #fff; border-radius: 3px; box-shadow: 0 0 3px #ccc; margin-right: 15px;}
    .img-url img {width: 100%;height: 100%;}

    .RecordList-item-detail-date, .RecordList-item-detail-price-mb {display: none;}
    .RecordList-item-detail-d {width: 70%;}
    .img-d {width: 100%;}
    .RecordList-item-detail-title, .RecordList-item-track, .RecordList-item-state div {font-size: 16px;}
    .RecordList-item-detail-title, .RecordList-item-detail-date {width: 100%;}
    .RecordList-item-detail-price b{font-size: 26px;}
    .RecordList-item-track {text-decoration: underline;}
    .RecordList-item-state {white-space: nowrap;margin: 0 80px;}
  }
  @media (max-width: 750px) {
    .ForRecord {
      margin: vw(23);padding-bottom: vw(1);
      .RecordList-item-date,.RecordList-item-detail-price {display: none;}
      .RecordList-item-detail-date {margin-top: vw(5);}
      .RecordList-item-detail-date, .RecordList-item-detail-price-mb {display: block;white-space: nowrap;}
      .RecordList-item-detail {padding: vw(25);border-bottom: 0; width: 100%;}
      .img-url {width: vw(90); height: vw(90);margin-right: vw(11);min-width: vw(90);}
      .RecordList-item-detail-title {font-size: vw(27);}
      
      .RecordList-item-detail-d {max-width: 80%;width: 100%}
      .RecordList-item-detail-price-mb, .RecordList-item-state-mb {font-size: vw(24)!important;}
      .RecordList-item-detail-price-mb b{font-size: vw(28);}
      .RecordList-item-track-mb,.RecordList-item-track-mba {margin-top: 1px;background: #F5EFF8; height: vw(73);border-bottom: 1px #DBC3E7 solid;line-height: vw(73); padding-right: vw(20); text-decoration: underline;}
      .RecordList-item-track-mbn {min-width: auto!important;}

      .RecordList-item-state {margin: 0 0 0 vw(20);}
    }
  }
</style>
<style>
  .ForRecord .el-pagination {padding: 20px 0;}
  #app  .ForRecord .el-pagination .btn-next {margin-right: 0;}
</style>